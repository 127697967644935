<template>
  <basic-block title="全校考勤统计" :isShowTitle="false" class="setting_main">
    <div class="two_main">
      <div class="left"></div>
      <div class="mid">
        <span class="label">教师考勤：</span>
        <div>
          <el-switch v-model="allSetting.enableTeacherCheckIn" inactive-color="#f5f5f5"></el-switch>

          <div class="tips" style="margin-top: 12px">
            <i class="iconfont iconxinxi"></i>
            <span class="tip">关闭考勤开关后，教师课堂考勤的功能将不能使用，考勤历史记录仍可以查看、考勤设置可以变更</span>
          </div>
        </div>
        <span class="label">考勤日期：</span>
        <div class="weeks">
          <el-checkbox-group v-model="allSetting.teacherWeek">
            <el-checkbox v-for="(v, k) in weeks" :key="k" :label="v.value">{{
              v.label
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <span class="label">课堂考勤时间：</span>
        <div class="tips">
          <i class="iconfont iconxinxi"></i>
          <span class="tip">每节课的考勤时间段默认为“基础信息设置-教学时间管理”添加的课节时间，</span>
          <el-link type="primary" @click="gotoClick">点击跳转设置</el-link>
        </div>
      </div>
      <div class="right">
        <img src="@/assets/images/attendance.png" alt width="214px" height="160px" />
      </div>
    </div>
    <div class="buttons">
      <!-- <el-button :size="$publicConfig.buttonSize" @click="$router.go(-1)">返回</el-button> -->
      <el-button :size="$publicConfig.buttonSize" type="primary" @click="setTeacherCheckConfig">保存</el-button>
    </div>
  </basic-block>
</template>

<script>
import {
  getAttendanceSetting,
  setTeacherCheckConfig
} from "@/http/setting";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      weeks: [
        { label: "周一", value: "0" },
        { label: "周二", value: "1" },
        { label: "周三", value: "2" },
        { label: "周四", value: "3" },
        { label: "周五", value: "4" },
        { label: "周六", value: "5" },
        { label: "周日", value: "6" },
      ],
      dialog: false,
      //学校考勤配置
      allSetting: {
        teacherWeek: [],
      },
    };
  },
  components: {},
  mounted() { },
  computed: {
    ...mapGetters(["userInfo", "yearsInfo"]),
  },
  created() {
    this.init();
  },
  methods: {
    gotoClick() {
      window.parent.location.href = window.parent.location.href.split('#/')[0] + '#/basicInfoManagement/teach-management'
    },
    init() {
      getAttendanceSetting(this.userInfo.tnId).then((res) => {
        if(res.retCode == 1) {
          let { data } = res;
          data.teacherWeek = data.teacherWeek
            ? data.teacherWeek.split(",")
            : [];
          this.allSetting = data;
        }
      });
    },
    setTeacherCheckConfig() {
      let data = JSON.parse(JSON.stringify(this.allSetting));
      data.teacherWeek = data.teacherWeek.join(",");
      setTeacherCheckConfig(data).then((res) => {
        if(res.retCode == 1) {
          this.$message.success("考勤设置保存成功");
        }
      });
    },
    setAttendanceSwitch() {
    }
  },
};
</script>

<style lang="scss">
.setting_main {
  .main_content {
    margin-top: 0px !important;
  }
  .el-input__icon {
    line-height: 24px;
  }
  .scoll_tab {
    height: calc(100% - 104px);
    overflow-y: auto;
  }
  .buttons {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    background: #ffffff;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .weeks {
    width: 100%;
    height: 50px;
    background: #fafafc;
    border-radius: 2px;
    border: 1px solid #ebedf1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .grades {
    padding: 0 24px;
    width: 100%;
    background: #fafafc;
    border-radius: 2px;
    border: 1px solid #ebedf1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .two_main {
    display: flex;
    margin: 0 0 24px 0;
    .left {
      flex: 1;
    }

    .mid {
      flex: 0 1 640px;
      .label {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        margin: 24px 0 8px 0;
        display: inline-block;
      }

      .tips {
        width: 100%;
        height: 32px;
        background: #f5f6f8;
        border-radius: 2px;
        border: 1px solid #c1cad8;
        font-size: 13px;
        display: flex;
        align-items: center;
        .iconxinxi {
          margin: 0 12px;
          color: $main-color;
        }
        .tip {
          color: #999999;
        }
      }

      .attendance_time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .el-radio-group {
          width: calc(100% - 108px);
        }
        .batch {
          width: 106px;
          height: 32px;
          border: 1px solid #999999;
          color: #666666;
          line-height: 32px;
          text-align: center;
        }
        .batch:hover {
          cursor: pointer;
          border: 1px solid $main-color;
          color: $main-color;
        }
      }
    }
    .right {
      flex: 1;
      text-align: center;
      margin-top: 50px;
    }
  }
  .first_main {
    margin: 33px 16px;
    height: 204px;

    color: #999999;
    display: flex;
    .first_main_left {
      flex: 0 0 990px;
      background: #f7f8fa;
      padding: 24px 32px;
    }
    .first_main_right {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .checks {
      margin-bottom: 24px;
    }
    .iconxinxi {
      color: $main-color;
      margin-right: 20px;
    }
    .top {
      display: flex;
      span {
        font-size: 14px;
        color: #666666;
        display: block;
      }
      .el-switch {
        margin-top: 4px;
        margin-bottom: 24px;
      }
      .right {
        margin-left: 60px;
      }
    }
  }
  .el-tabs {
    height: 100%;
    .el-tabs__content {
      height: calc(100% - 40px);
    }
    .el-tabs__item {
      color: #999999;
    }
  }
}
</style>